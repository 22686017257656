import getConfig from 'next/config';

import { trackingHostName } from '@/constants/hostname.ts';
import { request } from '@/utils/request.ts';

export interface PatchAppProxyPathPayload {
  orgId: string;
  proxyUrl: string;
}
const {
  serverRuntimeConfig: { AM_API_KEY },
} = getConfig();

export default async function patchAppProxyPath(payload: PatchAppProxyPathPayload) {
  const uri = `${trackingHostName}/aftership-branded-tracking-page/v1/organizations/${payload.orgId}/`;

  const response = await request(uri, {
    method: 'PATCH',
    body: JSON.stringify({
      returns_shopify_proxy_url: payload.proxyUrl,
    }),
    headers: { 'am-api-key': AM_API_KEY, 'Content-Type': 'application/json' },
  });
  return response.data || Promise.reject(response);
}
