import { t } from 'i18next';
import * as yup from 'yup';

import { PickupAddressSource, PickupInfomationData } from '@aftership/returns-logics-core';

import { getIsShowContectSection } from '../../hooks/usePickupInfomation';

export const pickupInfomationSchema = () =>
  yup.object<PickupInfomationData>().shape({
    pickupContact: yup
      .object()
      .when(
        ['$isMissingPhoneNumber', 'pickupAddressSource'],
        ([isMissingPhoneNumber, pickupAddressSource]) => {
          // 如果丢失了电话号码或者来源是手动输入，contact 信息是必须的

          const { isShowInnerContactSection, isShowOuterContactSection } = getIsShowContectSection(
            pickupAddressSource,
            isMissingPhoneNumber,
          );

          const needValidatePhoneNumber = isShowInnerContactSection || isShowOuterContactSection;
          if (needValidatePhoneNumber) {
            return yup.object().shape({
              firstName: yup.string().nullable().notRequired(),
              lastName: yup.string().trim().required(t('name.required')),
              phoneNumber: yup.string().trim().required(t('page.gift.form.phoneNumber')),
            });
          }
          return yup.object().nullable().notRequired();
        },
      ),
    pickupAddressSource: yup.mixed().oneOf(Object.values(PickupAddressSource)).required(),

    pickupAddress: yup.object().when('pickupAddressSource', ([source]) => {
      if (source === PickupAddressSource.order) {
        return yup.object().nullable().notRequired();
      }
      return yup.object().shape({
        addressLine1: yup.string().trim().required(t('page.gift.form.address')),
        city: yup.string().trim().required(t('page.gift.form.city')),
        state: yup.string().when(['country', '$countries'], ([country, countries]) => {
          if (countries?.[country]?.sublist?.length) {
            return yup.string().trim().required(t('page.gift.form.state'));
          }
          return yup.string().trim().nullable().notRequired();
        }),
        postalCode: yup.string().trim().required(t('page.gift.form.postalCode')),
        country: yup.string().trim().required(t('page.gift.form.country')),
      });
    }),
    pickupAppointmentDate: yup.string().trim().required(t('pickup.date.required')),
    pickupInstructions: yup.string().nullable().notRequired(),
  });
