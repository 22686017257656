/* eslint-disable max-lines */
import {
  ActorRefFrom,
  DoneActorEvent,
  ErrorActorEvent,
  SnapshotFrom,
  and,
  assertEvent,
  assign,
  enqueueActions,
  not,
  raise,
  sendParent,
  sendTo,
  setup,
} from 'xstate';

import { getPostReturnPayload, resolutionIsRefund } from './getPostReturnPayload';
import { getPostReviewPayload } from './getPostReviewPayload';
import {
  Events,
  PreviewExchangeItem,
  ReviewInput,
  ReviewMachineContext,
  ReviewMachineOutput,
} from './type';

import { ErrorResponse } from '../../../core/request/ErrorResponse';
import { RefundResolutionOrder, Resolution, SummaryTotalType } from '../../constant';
import { getReturnById, updateIntentionData } from '../../promise';
import { getMatchingResolutions } from '../../promise/resolution';
import {
  deleteDraftReturnsActor,
  getDraftReturnByIdActor,
  getDraftReturnsActor,
  postReturnPreviewActor,
  postReturnsActor,
} from '../../promise/review';
import { EfaMethod, Preview, RefundDestinationType, ReturnItemsPayload } from '../../types';
import { IReturnIntentions } from '../../types/intention';
import { isJWTError, takeMainActorRef } from '../../utils/eventUtils';
import { isRefundDestination } from '../../utils/refund';
import { resolveIntentionData } from '../resolveIntentionData';

const POLLING_MAX_COUNT = 10;
const MAX_DELAY = 1 * 10_000;

/**
 * 1. 普通 refund
 * 2. EFA + refund
 * 3. EFA + paid
 * 4. remove EFA items，变为 refund
 */

/**
 * 1. resolution --> refund resolution, no match
 * 2. resolution -> EFA, replace, preview.outcome === refund --> fetch resolutuon
 * 3. remove EFA --> refund resolution --> fetch resolution --> refech return preview
 */
export const reviewSubFlow = setup({
  actors: {
    postReturnPreviewActor,
    postReturnsActor,
    getDraftReturnsActor,
    deleteDraftReturnsActor,
    getReturnById,
    getDraftReturnByIdActor,
    getMatchingResolutions,
    updateIntentionData,
    resolveIntentionData,
  },
  actions: {
    updateContextForOriginalPaymentInPreview: () => {},
    updateContextForExchangeInPreview: () => {},
    updateReviewContextInPreviewMode: () => {},
    updateRefundDestination: assign(({ context }) => {
      const appliedRefundResolution = context.appliedResolutions
        ?.filter((resolution) => resolutionIsRefund(resolution))
        .sort(
          (a, b) => RefundResolutionOrder.indexOf(a) - RefundResolutionOrder.indexOf(b),
        ) as RefundDestinationType[];

      return {
        refundDestination: resolutionIsRefund(context.resolution)
          ? context.resolution
          : appliedRefundResolution?.[0],
      };
    }),
    // post return-previews 接口之后 assign 的值
    updateReturnPreviewData: assign(({ event, context }) => {
      const hidePriceAndSummaryAlways = context.grayFeature?.hiddenSummaryAndPriceAlways;
      const previewData = (event as unknown as DoneActorEvent<Preview>).output;
      previewData.return_items?.forEach(
        (item) =>
          item?.replacement &&
          (item.replacement.price_set.presentment_money = hidePriceAndSummaryAlways
            ? null
            : item.replacement.price_set.presentment_money),
      );

      const exchangeItems = context?.exchangeItems ?? [];

      return {
        preview: previewData,
        allowUseInstantExchange: previewData.instant_exchange.available,
        instantExchange: !context?.allowUseInstantExchange
          ? Boolean(context?.allowUseInstantExchange)
          : Boolean(context?.instantExchange),
        exchangeItems:
          previewData?.exchange_items?.map((item) => ({
            ...exchangeItems?.find((i) => i.variantId === item.external_variant_id),
            productId: item.external_product_id,
            variantId: item.external_variant_id,
            quantity: item.quantity,
            price: hidePriceAndSummaryAlways ? null : item.price_set.presentment_money,
          })) || exchangeItems,
        shippingAddress: previewData?.exchange_shipping_address,
        reviewError: null,
      };
    }),
  },
  types: {
    context: {} as ReviewMachineContext,
    events: {} as Events,
    input: {} as ReviewInput,
    output: {} as ReviewMachineOutput,
  },
  guards: {
    isStopPolling: ({ context }) => {
      return context.pollCount >= POLLING_MAX_COUNT || !!context?.draftReturn?.return_id;
    },
    isDraftId: ({ context }) => {
      return !!context?.draftId;
    },
    isEFAonStore: ({ context }) => {
      return !!(
        context?.resolution &&
        context.resolution === Resolution.ExchangeForAnything &&
        context.efaMethod === EfaMethod.inStore
      );
    },
    isValidateData: ({ context }) => {
      return !!context?.selectedItems?.length && !!context?.resolution && !!context?.returnMethod;
    },
    isReplaceOrEFARefund: ({ context }) => {
      return (
        [Resolution.ExchangeForAnything, Resolution.ReplaceTheSameItem].includes(
          context.resolution!,
        ) && context.preview?.summary?.financial_outcome === SummaryTotalType.Refund
      );
    },
    isChangeToRefundResolution: ({ context }) => {
      return (
        context.resolution === Resolution.ExchangeForAnything && !context.exchangeItems?.length
      );
    },
    hasRefundDestination: ({ context }) => !!context.refundDestination,
  },
  delays: {
    timeout: ({ context }) => {
      return Math.min(1000 + 200 * 2 ** context.pollCount, MAX_DELAY);
    },
  },
}).createMachine({
  initial: 'validateData',
  entry: ['updateReviewContextInPreviewMode'],
  context: ({ input }) => {
    return {
      ...input,
      pollCount: 0,
      refundDestination: isRefundDestination(input.resolution)
        ? (input.resolution as RefundDestinationType)
        : null,
      exchangeItems:
        input?.exchangeItems?.map((exchangeItem) => {
          const hidePriceAndSummaryAlways = input.grayFeature.hiddenSummaryAndPriceAlways;
          return {
            ...exchangeItem,
            price: hidePriceAndSummaryAlways ? null : exchangeItem.price,
          };
        }) ?? [],
    };
  },
  output: ({ context }) => {
    return {
      rmaId: context.rmaId!,
      returns: context.returns,
    };
  },
  states: {
    validateData: {
      always: [
        {
          guard: 'isValidateData',
          target: 'exchangeCalculation',
        },
        {
          actions: sendParent(() => ({
            type: 'GO_TO_ORDER_LOOKUP',
          })),
        },
      ],
    },
    exchangeCalculation: {
      initial: 'decision',
      states: {
        decision: {
          always: [
            {
              guard: 'isEFAonStore',
              target: 'loading',
            },
            {
              target: 'done',
            },
          ],
        },
        loading: {
          tags: 'loading',
          invoke: {
            id: 'resolveIntentionData',
            src: 'resolveIntentionData',
            input: ({ context }) => {
              return {
                intentionId: context.intentionId,
                token: context.token,
              };
            },
            onDone: {
              target: 'success',
              actions: [
                assign({
                  exchangeItems: ({ event, context }) => {
                    const hidePriceAndSummaryAlways =
                      context.grayFeature?.hiddenSummaryAndPriceAlways;
                    return event.output.exchangeItems?.map((exchangeItem) => ({
                      ...exchangeItem,
                      price: hidePriceAndSummaryAlways ? null : exchangeItem.price,
                    }));
                  },
                  intentionData: ({ event }) => {
                    return event.output.intentionData;
                  },
                }),
              ],
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              {
                target: 'error',
              },
            ],
          },
        },
        success: {
          tags: 'success',
          type: 'final',
        },
        error: {
          tags: 'error',
        },
        done: {
          type: 'final',
        },
      },
      onDone: {
        target: 'postReturnPreviewActor',
      },
    },
    postReturnPreviewActor: {
      id: 'postReturnPreview',
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            id: 'postReturnPreviewActor',
            src: 'postReturnPreviewActor',
            input: ({ context }) => {
              return getPostReviewPayload(context);
            },
            onDone: {
              target: 'success',
              actions: 'updateReturnPreviewData',
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              {
                target: 'error',
                actions: assign({
                  reviewError: ({ event }) => {
                    return event.error as ErrorResponse;
                  },
                }),
              },
            ],
          },
        },
        success: {
          tags: 'success',
          type: 'final',
        },
        error: {
          tags: 'error',
        },
      },
      onDone: [
        {
          guard: and(['isReplaceOrEFARefund', not('hasRefundDestination')]),
          target: 'getMatchingResolutions',
          description:
            '如果 replace or EFA 少退，去获取 resolution matching 接口, 选择 refund resolution',
        },
        // {
        //   guard: and(['isReplaceOrEFARefund', 'hasRefundDestination']),
        //   description: '如果 replace 或 EFA refund 场景, 且有 refund destination, 直接去 refetch',
        //   target: 'refetchReturnPreview',
        // },
        {
          guard: and([not('isReplaceOrEFARefund'), 'hasRefundDestination']),
          target: 'refetchReturnPreview',
          actions: assign({
            refundDestination: null,
          }),
          description: `如果 replace 和 EFA 没有走 refund, 但是曾经存了 refund destination 的值, 那么清空 refund destination, 重新 refetch 一次数据。
          出现这种场景一般是 EFA + refund, 然后在 review 页面中又添加了购物车, 变成 EFA+ paid`,
        },
      ],
    },
    getMatchingResolutions: {
      initial: 'loading',
      description:
        '获取 resolution 数据，根据 store credit, original payment 的顺序，默认选择第一个',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            src: 'getMatchingResolutions',
            input: ({ context }) => ({
              token: context?.token!,
              payload: {
                returnItems: context.selectedItems.map<ReturnItemsPayload>((item) => ({
                  item_id: item.itemId,
                  quantity: item.quantity,
                  reason: item.selectedReason,
                  subreason: item.selectedSubReason ?? '',
                })),
                isGiftReturn: !!context?.isGiftReturn,
              },
            }),
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              {
                target: 'error',
              },
            ],
            onDone: {
              target: 'success',
              actions: [
                assign(({ context, event }) => {
                  const appliedResolutions =
                    event.output.applied_resolutions?.filter(
                      (item) =>
                        !context.resolutionWhiteList?.length ||
                        context.resolutionWhiteList.includes(item),
                    ) ?? [];

                  return {
                    appliedResolutions,
                  };
                }),
                'updateRefundDestination',
              ],
            },
          },
        },
        success: {
          type: 'final',
          tags: 'success',
        },
        error: {
          tags: 'error',
        },
      },
      onDone: [
        {
          guard: 'isReplaceOrEFARefund',
          target: 'refetchReturnPreview',
        },
      ],
    },
    refetchReturnPreview: {
      id: 'refetchReturnPreview',
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            id: 'postReturnPreviewActor',
            src: 'postReturnPreviewActor',
            input: ({ context }) => {
              return getPostReviewPayload(context);
            },
            onDone: {
              target: 'success',
              actions: 'updateReturnPreviewData',
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              {
                target: 'error',
                actions: assign({
                  reviewError: ({ event }) => {
                    return event.error as ErrorResponse;
                  },
                }),
              },
            ],
          },
        },
        success: {
          tags: 'success',
          type: 'final',
        },
        error: {
          tags: 'error',
        },
      },
    },
    postReturnsActor: {
      id: 'postReturns',
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            id: 'postReturnsActor',
            src: 'postReturnsActor',
            input: ({ context, event }) => {
              assertEvent(event, 'SUBMIT_GENARATE_RETURN');

              return getPostReturnPayload(context, event?.data);
            },
            onDone: {
              target: 'success',
              actions: assign({
                returns: ({ event }) => event.output,
                rmaId: ({ event }) => event.output?.rma_id,
                draftId: ({ event }) => event.output?.draft_return?.id,
                returnError: () => null,
              }),
            },

            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              {
                target: 'error',
                actions: assign({
                  returnError: ({ event }) => {
                    return event.error as ErrorResponse;
                  },
                }),
              },
            ],
          },
        },
        success: {
          tags: 'success',
          type: 'final',
        },
        error: {
          // TODO 后续重构成事件抛出错误
          tags: 'error',
          after: {
            1000: {
              actions: [
                assign({
                  returnError: () => null,
                }),
              ],
            },
          },
        },
      },
      onDone: [
        {
          guard: ({ context }) => {
            return !!context?.rmaId;
          },
          target: 'done',
        },
      ],
    },
    createNewRequest: {
      initial: 'getDraftReturnsActor',
      states: {
        getDraftReturnsActor: {
          initial: 'loading',
          states: {
            loading: {
              tags: 'loading',
              invoke: {
                id: 'getDraftReturnsActor',
                src: 'getDraftReturnsActor',
                input: ({ context }) => {
                  return {
                    token: context?.token,
                    orderId: context.orderId,
                  };
                },
                onDone: {
                  target: 'success',
                  actions: assign({
                    draftId: ({ event }) => {
                      return event.output?.draft_returns?.[0]?.id;
                    },
                  }),
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  {
                    target: 'error',
                  },
                ],
              },
            },
            success: {
              always: [
                {
                  guard: 'isDraftId',
                  target: '#deleteDraftReturnsActor',
                },
                {
                  target: 'done',
                },
              ],
            },
            done: {
              type: 'final',
              tags: 'success',
            },
            error: {
              tags: 'error',
            },
          },
        },
        deleteDraftReturnsActor: {
          id: 'deleteDraftReturnsActor',
          initial: 'loading',
          states: {
            loading: {
              tags: 'loading',
              invoke: {
                id: 'deleteDraftReturnsActor',
                src: 'deleteDraftReturnsActor',
                input: ({ context }) => {
                  return {
                    token: context?.token,
                    draftId: context?.draftId!,
                  };
                },
                onDone: {
                  target: 'success',
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  {
                    target: 'error',
                  },
                ],
              },
            },
            success: {
              // 在状态里立即消费这个事件，不要在 onDone 里消费
              entry: raise({ type: 'SUBMIT_GENARATE_RETURN' }),
              tags: 'success',
              type: 'final',
            },
            error: {
              tags: 'error',
            },
          },
        },
      },
    },
    deleteDraftReturns: {
      id: 'deleteDraftReturns',
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            id: 'deleteDraftReturns',
            src: 'deleteDraftReturnsActor',
            input: ({ context, event }) => {
              assertEvent(event, 'DELETE_DRAFT_RETURN');
              return {
                token: context?.token,
                draftId: event?.data?.draftId!,
              };
            },
            onDone: {
              target: 'success',
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              {
                target: 'error',
              },
            ],
          },
        },
        success: {
          tags: 'success',
          type: 'final',
        },
        error: {
          tags: 'error',
        },
      },
    },
    getReturnById: {
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            id: 'getReturnById',
            src: 'getReturnById',
            input: ({ context, event }) => {
              assertEvent(event, 'GET_RETURN_BY_ID');
              return {
                token: context?.token!,
                returnId: event.data.returnId,
              };
            },
            onDone: {
              target: 'success',
              actions: assign({
                rmaId: ({ event, context }) => {
                  return context?.returns?.created_return?.rma_id || event.output?.rma_id;
                },
              }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              {
                target: 'error',
              },
            ],
          },
        },
        success: {
          type: 'final',
        },
        error: {
          tags: 'error',
        },
      },
      onDone: {
        target: 'done',
      },
    },

    pollingGetDraftReturn: {
      id: 'pollingGetDraftReturn',
      initial: 'fetching',
      // 初始化 pollCount
      entry: () =>
        assign({
          pollCount: () => 0,
        }),
      states: {
        fetching: {
          tags: 'loading',
          invoke: {
            id: 'getDraftReturnByIdActor',
            src: 'getDraftReturnByIdActor',
            input: ({ context }) => {
              return {
                token: context?.token!,
                draftId: context?.draftId!,
              };
            },
            onDone: {
              target: 'delay',
              actions: assign({
                draftReturn: ({ event }) => event.output,
              }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              {
                target: 'error',
              },
            ],
          },
        },
        delay: {
          after: {
            timeout: {
              target: 'checking',
            },
          },
        },
        checking: {
          always: [
            {
              target: 'success',
              guard: 'isStopPolling',
              actions: [
                assign({
                  rmaId: ({ context }) => {
                    return (
                      context?.returns?.created_return?.rma_id || context?.draftReturn?.return_id
                    );
                  },
                }),
              ],
            },
            {
              target: 'fetching',
              actions: assign({
                pollCount: ({ context }) => {
                  return context.pollCount + 1;
                },
              }),
            },
          ],
        },
        success: {
          tags: 'success',
          type: 'final',
        },
        error: {
          tags: 'error',
        },
      },
      onDone: [
        {
          guard: ({ context }) => {
            return !!context?.rmaId;
          },
          target: 'done',
        },
      ],
    },
    stopPollingDraftReturn: {
      initial: 'success',
      states: {
        success: {
          type: 'final',
          tags: 'success',
        },
      },
      onDone: [
        {
          guard: ({ context }) => {
            return !!context?.rmaId;
          },
          target: 'done',
        },
      ],
    },
    updateIntentionData: {
      initial: 'decision',
      states: {
        decision: {
          always: [
            {
              guard: 'isEFAonStore',
              target: 'loading',
            },
            {
              target: 'done',
            },
          ],
        },
        loading: {
          tags: 'loading',
          invoke: {
            src: 'updateIntentionData',
            input: ({ context, event }) => {
              assertEvent(event, 'UPDATE_INTENTION_DATA');
              return {
                token: context.token,
                payload: {
                  ...(context.intentionData as IReturnIntentions),
                  exchange_items: event.data.exchangeItems,
                },
              };
            },
            onDone: {
              target: 'success',
              actions: [
                assign({
                  exchangeItems: ({ event, context }) => {
                    return event.output?.exchange_items?.reduce((acc, item) => {
                      const currentItem = context.exchangeItems?.find(
                        (i) => i.variantId === item.external_variant_id,
                      );
                      if (currentItem)
                        acc.push({
                          ...currentItem,
                          quantity: item.quantity,
                        });
                      return acc;
                    }, [] as PreviewExchangeItem[]);
                  },
                }),
                // 保存成功后，重新去获取 preview 数据
                sendTo(({ self }) => self, {
                  type: 'REFETCH_PREVIEW',
                }),
              ],
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              {
                target: 'error',
              },
            ],
          },
        },
        success: { tags: 'success', type: 'final' },
        error: { tags: 'error', type: 'final' },
        done: { type: 'final' },
      },
    },
    done: {
      type: 'final',
    },
  },
  on: {
    SUBMIT_GENARATE_RETURN: {
      target: '.postReturnsActor',
    },
    REFETCH_PREVIEW: {
      target: '.validateData',
    },
    CREATE_NEW_REQUEST: {
      target: '.createNewRequest',
    },
    SET_INSTANT_EXCHANGE: {
      actions: assign({
        instantExchange: ({ event }) => event.data.instantExchange,
      }),
    },
    GET_RETURN_BY_ID: {
      target: '.getReturnById',
    },
    POLLING_GET_DRAFT_RETURN: {
      target: '.pollingGetDraftReturn',
    },
    POLLING_CANCEL_DRAFT_RETURN: {
      target: '.stopPollingDraftReturn',
    },
    DELETE_DRAFT_RETURN: {
      target: '.deleteDraftReturns',
    },
    UPDATE_REFUND_DESTINATION: {
      target: '.refetchReturnPreview',
      actions: [
        assign({
          refundDestination: ({ event }) => event.data.refundDestination,
        }),
      ],
    },
    UPDATE_INTENTION_DATA: {
      target: '.updateIntentionData',
    },
    CLEAR_RETURN_ERROR: {
      actions: assign({
        returnError: () => null,
      }),
    },
    UPDATE_EFA_ITEMS: {
      actions: [
        assign({
          exchangeItems: ({ event }) => {
            return event.data.exchangeItems;
          },
        }),
        sendTo(
          ({ self }) => self,
          ({ event, context }) => {
            if (context.efaMethod === EfaMethod.inStore)
              return {
                type: 'UPDATE_INTENTION_DATA',
                data: {
                  exchangeItems: event.data.exchangeItems?.map((item) => ({
                    external_product_id: item.productId,
                    external_variant_id: item.variantId,
                    quantity: item.quantity,
                  })),
                },
              };
            return {
              type: 'REFETCH_PREVIEW',
            };
          },
        ),
        sendParent(
          ({ event }) => {
            return {
              type: 'UPDATE_EXCHANGE_ITEMS',
              data: {
                exchangeItems: event.data.exchangeItems,
              },
            };
          },
          {
            id: 'update_parent_exchange_items',
            // TODO 临时解决方法，解决因为 snapshot 保存了状态，导致刷新的时候，还是之前的状态
            // 由于改变的是 parent 的 context，所以需要 delay 一下, 因为 parent 的 context 是在当前 tick 更新的，所以 snapshot 里的状态会被保存,导致了刷新的时候，还是之前的状态。
            // 因为 delay 会在下一个 tick 执行，所以不会保存，在下个 tick 会重新计算 snapshot
            delay: 0,
          },
        ),
      ],
    },
    PREVIEW_UPDATE_CONTEXT_FOR_ORIGINAL: {
      actions: 'updateContextForOriginalPaymentInPreview',
    },
    PREVIEW_UPDATE_CONTEXT_FOR_REPLACE: {
      actions: 'updateContextForExchangeInPreview',
    },
  },
});

export type ReviewSubFlowActorRef = ActorRefFrom<typeof reviewSubFlow>;

export type ReviewSubFlowSnapshot = SnapshotFrom<typeof reviewSubFlow>;
