import { useEffect, useRef } from 'react';

import { usePreviewContext } from '@aftership/preview-kit/client';
import { MainMachineActorRef, MainMachineSnapshot } from '@aftership/returns-logics-core';
import { useMainFlowContext } from 'returns-logics/react';

import { parsePath } from '@/utils/parse-path';

import { useCachedAppProxy } from './useCachedAppProxy';
import { useUniversalRouting } from './useUniversalRouting';

export const xStateMetaData = new Map<string, Record<string, string>>();

type StateValueMapping = Record<
  string,
  {
    path: () => string;
    getParam?: (mainFlowActor: MainMachineActorRef) => Record<string, string>;
    getSearch?: () => string;
    syncStateAfterRouteChange: (
      mainFlowActor: MainMachineActorRef,
      router: ReturnType<typeof useUniversalRouting>,
    ) => void;
    onExit?: VoidFunction;
  }
>;

const useLatest = <T>(value: T): { current: T } => {
  const ref = useRef(value);
  ref.current = value;
  return ref;
};

export default useLatest;

// FIXME: 有一些有参数的 url，有些做不到状态同步路由，勉强做到路由同步状态
const stateValueUrlMapping: StateValueMapping = {
  orderLookup: {
    path: () => '/',
    syncStateAfterRouteChange: (mainFlowActor) => {
      mainFlowActor.send({ type: 'GO_TO_ORDER_LOOKUP' });
    },
  },
  giftReturn: {
    path: () => '/gift-return',
    syncStateAfterRouteChange: (mainFlowActor) => {
      mainFlowActor.send({ type: 'GO_TO_GIFT_RETURN' });
    },
  },
  itemSelection: {
    path: () => '/request-returns',
    syncStateAfterRouteChange: (mainFlowActor) => {
      mainFlowActor.send({ type: 'GO_TO_ITEM_SELECTION' });
    },
  },
  resolution: {
    path: () => '/return-resolution',
    syncStateAfterRouteChange: (mainFlowActor) => {
      mainFlowActor.send({ type: 'GO_TO_RESOLUTION' });
    },
  },
  replaceTheSameItem: {
    path: () => '/return-replacement',
    syncStateAfterRouteChange: (mainFlowActor) => {
      mainFlowActor.send({ type: 'GO_TO_REPLACE' });
    },
  },
  exchangeInApp: {
    path: () => {
      if (xStateMetaData.has('exchangeInApp') && xStateMetaData.get('exchangeInApp')?.productId) {
        return `/exchange/products/[id]`;
      }
      return '/exchange/products';
    },
    getParam: () => {
      const productId = xStateMetaData.has('exchangeInApp')
        ? xStateMetaData.get('exchangeInApp')?.productId || ''
        : '';
      return { id: productId };
    },
    syncStateAfterRouteChange: (mainFlowActor) => {
      mainFlowActor.send({ type: 'GO_TO_EXCHANGE_IN_APP' });
    },
  },
  returnMethod: {
    path: () => '/return-method',
    syncStateAfterRouteChange: (mainFlowActor) => {
      mainFlowActor.send({ type: 'GO_TO_RETURN_METHOD' });
    },
  },
  review: {
    path: () => '/review',
    syncStateAfterRouteChange: (mainFlowActor) => {
      if (mainFlowActor.getSnapshot().value === 'returnDetail') {
        mainFlowActor.send({ type: 'GO_TO_RETURN_LIST' });
      } else mainFlowActor.send({ type: 'GO_TO_REVIEW' });
    },
  },
  returnDetail: {
    path: () => '/return-detail/[rmaId]',
    getParam: (mainFlowActor) => {
      return {
        rmaId:
          mainFlowActor.getSnapshot().children?.returnDetailSubFlow?.getSnapshot()?.context
            ?.rmaId || '',
      };
    },
    getSearch: () => {
      return xStateMetaData?.has('review') ? xStateMetaData.get('review')?.query || '' : '';
    },
    syncStateAfterRouteChange: (mainFlowActor, router) => {
      mainFlowActor.send({
        type: 'GO_TO_REDETAIL',
        data: {
          rmaId: router.getParam('rmaId'),
        },
      });
    },
    onExit: () => {
      if (xStateMetaData?.has('review')) {
        xStateMetaData.delete('review');
      }
    },
  },

  returnsList: {
    path: () => '/returns-list',
    syncStateAfterRouteChange: (mainFlowActor) => {
      mainFlowActor.send({ type: 'GO_TO_RETURN_LIST' });
    },
  },
};

// 不属于主流程的页面，不应该由 mainflow state 触发路由【例如打开 return-policy 页面，是新起了一个 return policy flow，main flow 仍然位于原来的状态】
const allowListUrlsInPreview = ['/return-policy', '/exchange/preview'];

const matchURI = (configPath: string, browserPath: string) => {
  const pattern = configPath.replace(/\/\[\w+\]/g, '/?([^/]*)');
  const regex = new RegExp(`^${pattern}$`);
  return regex.test(browserPath);
};

const buildPath = ({
  path,
  params,
  search = '',
}: {
  path: string;
  params?: Record<string, string>;
  search?: string;
}) => {
  return path.replace(/\[(\w+)\]/g, (_, key) => params?.[key] || ``) + search;
};

export const useSyncXStateAndRoute = () => {
  const stackRef = useRef<VoidFunction[]>([]);

  const mainFlowActor = useMainFlowContext();
  const router = useUniversalRouting();

  const pathnameRef = useRef<string>(router.pathname);

  const { pathPrefix } = useCachedAppProxy();

  let previousSnapshot = mainFlowActor.getSnapshot();

  const { isPreview } = usePreviewContext();

  // 将状态同步到路由
  const syncStateToRoute = (state: MainMachineSnapshot) => {
    // 如果当前状态变了，且当前路由需要由 xstate 触发，那么就触发路由跳转
    const pathname = pathnameRef.current;
    if (
      (state.value !== previousSnapshot.value &&
        Object.values(stateValueUrlMapping).some((config) => matchURI(config.path(), pathname))) ||
      (isPreview && allowListUrlsInPreview.includes(pathname))
    ) {
      const config = stateValueUrlMapping[state.value as keyof typeof stateValueUrlMapping];
      // TODO 优化
      const routerMethod = (config?.getParam?.(mainFlowActor)?.routerMethod || 'navigate') as
        | 'replace'
        | 'navigate';
      // 当前 state 对应的 url 是否匹配当前的 pathname，如果不匹配，就触发路由跳转
      if (config?.path && !matchURI(config?.path(), pathname)) {
        router?.[routerMethod]?.({
          pathname: config?.path(), // 和 next 一样的规则
          asPath: buildPath({
            path: config?.path(),
            params: config?.getParam?.(mainFlowActor),
            search: config?.getSearch?.(),
          }),
          params: config?.getParam?.(mainFlowActor),
        });
        config?.onExit?.();
      }
    }
    previousSnapshot = state;
  };

  // 将路由同步到状态
  const syncRouteToState = (pathname: string) => {
    const matchingEntry = Object.entries(stateValueUrlMapping).find(([, config]) =>
      matchURI(config.path(), pathname),
    );
    // 如果当前的路由能匹配到配置的 state，就触发 state 的变更
    if (matchingEntry) {
      const [stateValue, { syncStateAfterRouteChange }] = matchingEntry;
      if (mainFlowActor.getSnapshot().value !== stateValue) {
        stackRef.current.push(() => syncStateAfterRouteChange?.(mainFlowActor, router));
      }
    }
  };

  const handleRouteChangeComplete = (url: string) => {
    const pathname = parsePath(url).pathname;
    pathnameRef.current = pathPrefix ? pathname.replace(pathPrefix, '') : pathname;
    syncRouteToState(pathnameRef.current);
    stackRef.current?.pop()?.();
  };

  useEffect(() => {
    // 保证初始的值为空
    stackRef.current = [];
    syncRouteToState(pathnameRef.current); // 初始同步一次
    stackRef.current?.pop()?.(); // 初始同步一次

    router.events.on('beforeHistoryChange', handleRouteChangeComplete);
    const { unsubscribe } = mainFlowActor.subscribe((state) => {
      stackRef.current?.pop()?.();
      syncStateToRoute(state);
    });

    return () => {
      unsubscribe();
      router.events.off('beforeHistoryChange', handleRouteChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

/**
 * 一、通过 xstate 的状态同步到路由 syncStateToRoute ，syncStateAfterRouteChange 入栈等待触发，等待路由跳转触发后，
 * syncStateAfterRouteChange 出栈调用
 * 二、跳转成功后，触发路由变更，syncRouteToState 方法后，重新将 syncStateAfterRouteChange 入栈等待触发，
 * 此时在 subscribe 中将 入栈等待触发，等待路由跳转触发后，syncStateAfterRouteChange 出栈调用
 * 三、进入下一轮的 subscribe，syncStateToRoute ，syncStateAfterRouteChange 重新入栈等待触发，路由进行跳转
 * 四、路由跳转成功后， 调用 syncRouteToState，重新将 syncStateAfterRouteChange 入栈等待触发，此时上一个 syncStateAfterRouteChange 出栈调用。
 */
