var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5316bb862e793f0f2f1620672820f71480271f2c"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import getConfig from 'next/config';

const {
  publicRuntimeConfig: { APP_ENV, GIT_COMMIT_SHA1, BUILD_NUMBER },
} = getConfig();

const enabledSentry = ['staging', 'production', 'development', 'testing', 'release-kiwi'].includes(
  APP_ENV || '',
);
const alwaysReplays = ['testing', 'release-kiwi', 'development'].includes(APP_ENV || '');

// eslint-disable-next-line @typescript-eslint/naming-convention
Promise.all([import('@sentry/nextjs')]).then(([Sentry]) => {
  Sentry.init({
    dsn: 'https://ddf44fd4f2214302437cd36a0eb6f7ca@sentry.automizely.org/77',
    dist: BUILD_NUMBER,
    allowUrls: [/(http|https):\/\/.*\.(automizely|returnscenter)\.(?=com|io)/],
    enabled: enabledSentry,
    environment: APP_ENV,
    release: GIT_COMMIT_SHA1,
    ignoreErrors: [
      'Failed to fetch',
      'Request aborted',
      'Non-Error promise rejection captured with keys:',
      'sentry',
      'chunk.min.js',
      'Non-Error',
      'KeypressListener',
      'Unable to preload',
      'Importing a module',
      'AbortError',
    ],
    tracesSampleRate: 1.0,
    //  https://docs.sentry.io/platforms/javascript/guides/nextjs/performance/instrumentation/automatic-instrumentation/#configuration-options
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/.*api\.returnscenter\.(io|com)\/shopper/,
      /^https:\/\/.*api\.returnscenter\.(io|com)\/v2\/shopper/,
      /^https:\/\/.*api\.returnscenter\.(io|com)\/automizely\/shopping\/v2/,
      /^https:\/\/.*api\.automizely\.(io|com)\/(rc|returnscenter)\/(v3|v4)/,
    ],
    replaysSessionSampleRate: alwaysReplays ? 1.0 : 0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.browserTracingIntegration(),
      // https://docs.sentry.io/platforms/javascript/guides/nextjs/metrics/#configure-metrics
      Sentry.metrics.metricsAggregatorIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        networkDetailAllowUrls: [/(http|https):\/\/.*\.(automizely|returnscenter)\.(?=com|io)/],
        networkResponseHeaders: ['Request-Id', 'Cf-Ray'],
      }),
    ],
  });
});
