import { useFormContext } from 'react-hook-form';

import { PickupAddressSource, PickupInfomationData } from '@aftership/returns-logics-core';

import { useMainFlow } from '../../../hooks/useMainFlow';

export const getIsShowContectSection = (
  selectedAddressSource: PickupAddressSource,
  isMissingOrderInfo: boolean,
) => {
  const isShowAddressSection = selectedAddressSource === PickupAddressSource.manual;

  const isShowOuterContactSection = isMissingOrderInfo;

  return {
    // 是否展示外层的联系人信息
    isShowOuterContactSection,
    isShowAddressSection,

    // 如果有电话信息，但是 address 来源是手动输入，contact 信息是必须的，且在 address 里填写
    isShowInnerContactSection: isShowAddressSection && !isShowOuterContactSection,
  };
};

export const usePickupInfomation = () => {
  const mainFlow = useMainFlow();

  const { watch } = useFormContext<PickupInfomationData>();

  const selectedAddressSource = watch('pickupAddressSource');

  return getIsShowContectSection(
    selectedAddressSource,
    !!mainFlow?.context?.request?.orders?.missing_phone_number,
  );
};
