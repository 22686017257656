import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AmountPrice, Resolution, ShippingFeeOption } from '@aftership/returns-logics-core';

import { toCurrency } from '@/utils/price';

import { useGrayHideMode } from '../../hooks/useGrayHideMode';

interface IExchangeShippingProps {
  shippingFee?: AmountPrice | null;
  shippingFeeOption?: ShippingFeeOption | null;
}

export const useGetExchangeShippingValue = ({
  shippingFee,
  shippingFeeOption,
}: IExchangeShippingProps) => {
  const { t } = useTranslation();

  const shippingFeeValue = useMemo(() => {
    if (shippingFeeOption === ShippingFeeOption.CUSTOM) {
      return shippingFee ? toCurrency(shippingFee.amount, shippingFee.currency) : null;
    } else if (shippingFeeOption === ShippingFeeOption.FREE) {
      return t('page.description.free');
    }
    return null;
  }, [shippingFee, shippingFeeOption, t]);

  return shippingFeeValue;
};

export const useHideSummary = ({ resolution }: { resolution?: Resolution }) => {
  const { hideSummaryAlways, hideSummaryOnlyReplace } = useGrayHideMode({
    resolution,
  });
  // 如果是ReturnMethod 是 replace the same item, 并且属于灰度名单,需要隐藏价格还有 Summary
  const hideSummary = hideSummaryAlways || hideSummaryOnlyReplace;

  return {
    hideSummary,
  };
};
