import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { usePreviewContext } from '@aftership/preview-kit/client';
import {
  ExchangeChargeMode,
  ExchangeMode,
  InstantExchangeChargeTriggerStatusMap,
  Resolution,
  SummaryTotalType,
} from '@aftership/returns-logics-core';
import { useFlow } from 'returns-logics/react';

import useDevice from '@/hooks/useDevice';
import { useTranslateResolutionInfo } from '@/hooks/useResolutionInfo';
import { secondToDays } from '@/utils/date';

import MaximumReturnableQuantityExceededErrorModal from './components/ErrorModals/MaximumReturnableQuantityExceededErrorModal';
import StripeHoldPaymentErrorModal from './components/ErrorModals/StripeHoldPaymentErrorModal';
import ExpiredModal from './components/ExpiredModal';
import InstantExchange from './components/InstantExchange';
import PaymentModal from './components/PaymentModal';
import RefundMethod from './components/RefundMethod';
import SubmitButtonGroup from './components/SubmitButtonGroup';
import usePayStatusAction from './hooks/usePayStatusAction';
import { useReturnPreviewFlow } from './hooks/useReturnPreviewFlow';
import { useSubmitReturns } from './hooks/useSubmitReturns';
import useSummaryTotalUtil from './hooks/useSummaryTotalUtil';

import Summary from '../return-detail/components/Summary';
import { SummaryProvider } from '../return-detail/components/Summary/SummaryProvider';
import useCartStyle from '../return-detail/hooks/useCartStyle';
import useHideSummaryWidth from '../return-detail/hooks/useHideSummaryWidth';
import { xStateMetaData } from '../returns/hooks/useSyncXStateAndRoute';

const RightColumn = () => {
  const {
    currentStep,
    resolution,
    reviewError,
    returnPreview,
    storeConfig,
    exchangeItems,
    returnItems,
    reviewSubFlow,
    isLoading,
    returnPreviewFlowContext,
    returnPreviewFlowDispatch,
  } = useReturnPreviewFlow();
  const flow = useFlow();
  const { isPreview } = usePreviewContext();
  const { mobile } = useDevice();
  const { t } = useTranslation();
  const [isFold, setIsFold] = useState(true);
  const { isRefund } = useSummaryTotalUtil();
  const { mobileBackgroundColor } = useCartStyle();

  const hideSummaryWidth = useHideSummaryWidth();

  const isReplaceWithSameItem = resolution === Resolution.ReplaceTheSameItem;
  const financialOutcome = returnPreview?.summary?.financial_outcome;

  const { instant_exchange_charge_trigger_status, instant_exchange_charge_trigger_seconds } =
    storeConfig?.shopInfo ?? {};

  const showRefundMethod =
    !!resolution &&
    [Resolution.ExchangeForAnything, Resolution.ReplaceTheSameItem].includes(resolution) &&
    isRefund;

  const allowUseInstantExchange = reviewSubFlow?.context?.allowUseInstantExchange;
  const instantExchangeChecked = !!reviewSubFlow?.context?.instantExchange;

  const {
    data: returnsData,
    submit: submitReturns,
    status,
    isSubmitting,
    returnId,
    setPayStatus,
    setSubmitting,
    stopPollingDraftReturn,
    rmaId,
  } = useSubmitReturns();

  const {
    payModalVisible,
    payErrorModalVisible,
    noReturnableQuantityModalVisible,
    expiredModalVisible,
    onPayCancel,
    onAlreadyPaid,
    onPayErrorModalClose,
    onPayExpiredModalClose,
    onPayContinue,
    onPayNotReturnableQuantityModalClose,
  } = usePayStatusAction({
    status,
    rmaId,
    returnId,
    setPayStatus,
    setSubmitting,
    stopPollingDraftReturn,
  });

  const translatedResolutionInfo = useTranslateResolutionInfo();

  const isButtonDisabled = currentStep !== 'review' || !!reviewError || isLoading || isSubmitting;

  const hasChargePayment =
    !!returnsData?.draft_return?.is_instant_exchange &&
    Number(
      returnsData?.draft_return?.exchange_calculations?.exchange?.checkout_total?.amount || '0',
    ) > 0;

  const isCostOfReturnIncluded = !!returnPreview?.summary?.cost_of_return_included_in_calculation;
  const isChangeByOthers =
    flow.context?.storeConfig?.shopInfo?.exchange_mode === ExchangeMode.DifferentPrice ||
    flow.context?.storeConfig?.shopInfo?.exchange_rule_item_price_difference_settlement ===
      ExchangeChargeMode.ChargeByOthers;
  const isShowReplaceSameItemDesc =
    !isCostOfReturnIncluded &&
    isReplaceWithSameItem &&
    !!Number(returnPreview?.summary?.cost_of_return_set?.presentment_money?.amount) &&
    isChangeByOthers;

  const isSummaryLoading =
    reviewSubFlow?.matches({
      postReturnPreviewActor: 'loading',
    }) ||
    reviewSubFlow?.matches({
      refetchReturnPreview: 'loading',
    }) ||
    reviewSubFlow?.matches({ getMatchingResolutions: 'loading' });

  const originalActive = returnPreviewFlowContext?.appliedResolutions?.includes(
    Resolution.OriginalPayment,
  );
  const storeActive = returnPreviewFlowContext?.appliedResolutions?.includes(
    Resolution.StoreCredit,
  );

  const refundName = useMemo(() => {
    if (financialOutcome !== SummaryTotalType.Refund) return null;

    if (resolution === Resolution.ExchangeForAnything) {
      // EFA 的退款如果用户开启了 Store Credit 和 Original Payment，那么不展示 Refund name，而是额外显示 refund method 卡片。
      if (storeActive && originalActive) return null;
      if (storeActive) return translatedResolutionInfo?.[Resolution.StoreCredit].name;
      if (originalActive) return translatedResolutionInfo?.[Resolution.OriginalPayment].name;
      return null;
    } else if (resolution === Resolution.StoreCredit || resolution === Resolution.OriginalPayment) {
      return translatedResolutionInfo?.[resolution].name;
    } else {
      return null;
    }
  }, [financialOutcome, originalActive, resolution, storeActive, translatedResolutionInfo]);
  const createLoading = useMemo(
    () =>
      Boolean(
        reviewSubFlow?.matches({
          createNewRequest: {
            getDraftReturnsActor: 'loading',
          },
        }) ||
          reviewSubFlow?.matches({
            createNewRequest: {
              deleteDraftReturnsActor: 'loading',
            },
          }),
      ),
    [reviewSubFlow],
  );

  return (
    <Box width={hideSummaryWidth} backgroundColor={mobileBackgroundColor}>
      <Flex
        direction='column'
        gap={SpacingVars['12']}
        paddingBottom={mobile ? SpacingVars['8'] : 0}
      >
        <Flex direction='column' gap={SpacingVars['6']}>
          <SummaryProvider
            isFold={isFold}
            isMobile={mobile}
            setIsFold={setIsFold}
            resolution={resolution}
            isLoading={isSummaryLoading}
            previewSummary={returnPreview?.summary}
            exchangeItemsLength={exchangeItems?.length}
            returnItemsLength={returnItems?.length}
            financialOutcome={financialOutcome}
            isPreview={isPreview}
            refundName={refundName}
          >
            <Summary />
          </SummaryProvider>

          {isRefund && (
            <Text
              variant='body2'
              paddingX={mobile ? SpacingVars['4'] : 0}
              color={ColorVars['Grey']['Grey 1100']}
            >{`*${t('page.description.refundDescription')}`}</Text>
          )}
          {isShowReplaceSameItemDesc && (
            <Text
              variant='caption1'
              paddingX={mobile ? SpacingVars['4'] : 0}
              color={ColorVars['Grey']['Grey 1100']}
            >{`*${t('page.description.replaceSameItemDesc')}`}</Text>
          )}

          <RefundMethod showRefundMethod={showRefundMethod} />

          {allowUseInstantExchange && (
            <InstantExchange
              checked={instantExchangeChecked}
              triggerStatusName={
                instant_exchange_charge_trigger_status &&
                InstantExchangeChargeTriggerStatusMap?.[instant_exchange_charge_trigger_status]
              }
              triggerDays={secondToDays(instant_exchange_charge_trigger_seconds!)}
              onChange={(instantExchange) => {
                returnPreviewFlowDispatch?.({
                  type: 'SET_INSTANT_EXCHANGE',
                  data: {
                    instantExchange,
                  },
                });
              }}
            />
          )}
        </Flex>
        <SubmitButtonGroup
          isSubmitting={isSubmitting || createLoading}
          isDisabled={isButtonDisabled || isPreview || createLoading}
          resolution={resolution}
          onSubmit={() => {
            if (!isPreview) {
              xStateMetaData.set('review', {
                query: '?isFormReview=true',
              });
              submitReturns();
            }
          }}
        />
      </Flex>

      <PaymentModal
        isOpen={payModalVisible}
        draftReturn={returnsData?.draft_return}
        onCancel={onPayCancel}
        onContinue={onPayContinue}
        onAlreadyPaid={onAlreadyPaid}
        hasChargePayment={hasChargePayment}
        isInstantExchange={allowUseInstantExchange && instantExchangeChecked}
        isReplacementNeedCostOfReturnCharge={isCostOfReturnIncluded}
      />

      <ExpiredModal isOpen={expiredModalVisible} onClose={onPayExpiredModalClose} />

      <StripeHoldPaymentErrorModal
        isOpen={payErrorModalVisible}
        onClose={onPayErrorModalClose}
        hasChargePayment={hasChargePayment}
        onTryAgain={() => {
          onPayErrorModalClose();
          submitReturns();
        }}
      />

      <MaximumReturnableQuantityExceededErrorModal
        isOpen={noReturnableQuantityModalVisible}
        onClose={onPayNotReturnableQuantityModalClose}
        onConfirm={onAlreadyPaid}
      />
    </Box>
  );
};

export default RightColumn;
