import React from 'react';

import {
  Box,
  Flex,
  Icon,
  Pressable,
  RightChevron,
  Text,
  TextProps,
} from '@aftership/design-system';
import { ColorVars, RadiusVars, SpacingVars } from '@aftership/design-tokens';
import { PresentmentMoney } from '@aftership/returns-logics-core';

import CustomizationsTags from '@/components/ItemCard/CustomizationsTags';
import {
  productTitleContainer,
  variantTitleStyle,
} from '@/components/ItemCardContent/styles.css.ts';
import useDevice from '@/hooks/useDevice.ts';
import { toCurrency } from '@/utils/price';
import { extractStorefrontTags } from '@/utils/products';
import { renderIfNotNullOrUndefined } from '@/utils/render';

import { EllipsisText } from '../EllipsisText/index.ts';
import ImageWithFallback from '../ImageWithFallback/ImageWithFallback.tsx';

export interface ProductInfo {
  /**
   * 商品标题
   */
  productTitle: string;
  /**
   * 商品variant标题
   */
  variantTitle?: string;
  /**
   * 商品价格信息
   */
  price?: PresentmentMoney | null;
  /**
   * 商品的原始价格
   */
  originPrice?: PresentmentMoney | null;
  /**
   * 商品标签列表
   */
  productTags?: string[];
  /**
   * 商品数量
   */
  quantity?: number;

  productCoverUrl?: string;
}
export interface ItemCardContentProps {
  productTitleVariant?: TextProps['variant'];
  productInfo: ProductInfo;
  returnReason?: string;
  returnSubReason?: string;
  showMoreReason?: boolean;
  showMoreReasonClick?: () => void;
  rightContent?: React.ReactNode;
  productTitleContainerStyles?: string;
}
const ItemCardContent = ({
  productTitleVariant = 'subtitle1',
  productInfo,
  returnReason,
  returnSubReason,
  showMoreReason,
  showMoreReasonClick,
  rightContent,
  productTitleContainerStyles = productTitleContainer,
}: ItemCardContentProps) => {
  const isMobile = useDevice().mobile;
  const { productTitle, productTags, price, originPrice, variantTitle, quantity, productCoverUrl } =
    productInfo ?? {};

  const tags = extractStorefrontTags(productTags || []);

  // 是否展示原价. 当原价和现价都有值，且现价小于原价时展示原价
  const showOriginalPrice =
    originPrice?.amount && price?.amount && Number(price.amount) < Number(originPrice.amount);

  const renderProductTitle = () => {
    return renderIfNotNullOrUndefined(
      productTitle,
      <EllipsisText
        maxLine={isMobile ? 2 : 1}
        variant={productTitleVariant}
        color={ColorVars.Grey['Grey1200']}
        text={productTitle}
      />,
    );
  };

  const renderVariantTitle = () => {
    return renderIfNotNullOrUndefined(
      variantTitle,
      <Text variant={'body2'} className={variantTitleStyle} color={ColorVars.Grey['Grey 1100']}>
        {variantTitle}
      </Text>,
    );
  };

  const renderProductTags = () => {
    return renderIfNotNullOrUndefined(tags?.length, <CustomizationsTags tags={tags ?? []} />);
  };

  const renderReturnReason = () => {
    return renderIfNotNullOrUndefined(
      returnReason,
      <Pressable onPress={showMoreReason ? showMoreReasonClick : undefined}>
        <Flex gap={SpacingVars['1']} alignItems='center'>
          <EllipsisText
            maxLine={1}
            variant={'body2'}
            color={ColorVars.Grey['Grey 1100']}
            text={`${returnReason} ${returnSubReason ? `/ ${returnSubReason}` : ''}`}
          />
          {showMoreReason && <Icon source={RightChevron} size={SpacingVars['3']} />}
        </Flex>
      </Pressable>,
    );
  };

  // price 展示
  const renderPrice = () => {
    return (
      <Box marginTop={SpacingVars['1']}>
        <Flex direction='row' gap={SpacingVars['1']} alignItems={'baseline'}>
          {/* 是否展示原价 */}
          {showOriginalPrice && (
            <Text
              variant={'body2'}
              textDecoration={'line-through'}
              color={ColorVars.Grey['Grey 1000']}
            >
              {toCurrency(originPrice?.amount, originPrice.currency)}
            </Text>
          )}
          {price?.amount && price?.currency && (
            <Text variant={'body2'} color={ColorVars.Grey.Grey1200}>
              {toCurrency(price!.amount, price!.currency)}
            </Text>
          )}
          {!!quantity && (
            <Text variant={'body2'} color={ColorVars.Grey.Grey1200}>{`x ${quantity}`}</Text>
          )}
        </Flex>
      </Box>
    );
  };
  const renderRightContent = () => {
    return renderIfNotNullOrUndefined(rightContent, rightContent);
  };

  return (
    <Flex direction={'row'} gap={SpacingVars['4']} alignItems={'start'}>
      <Box borderRadius={RadiusVars['1']} overflow='hidden' minWidth={80}>
        <ImageWithFallback
          usingShopifyPreview
          width={80}
          height={80}
          src={productCoverUrl ?? ''}
          alt=''
        />
      </Box>

      <Flex className={productTitleContainerStyles}>
        <Flex direction='column'>
          {renderProductTitle()}
          {renderVariantTitle()}
          {renderProductTags()}
          {renderReturnReason()}
          {!isMobile && renderPrice()}
        </Flex>
        {isMobile && renderPrice()}
      </Flex>

      {renderRightContent()}
    </Flex>
  );
};

export default ItemCardContent;
