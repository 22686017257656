import {
  connectorsEndpoint,
  platformEndpoint,
  shopperEndpoint,
  shopperV2Endpoint,
  shopperV3Endpoint,
  shopperV4Endpoint,
  trackingEndpoint,
} from '@/config/endPoints';

export const hostName = shopperEndpoint;

export const v2hostName = shopperV2Endpoint;

export const v3hostName = shopperV3Endpoint;

export const v4hostName = shopperV4Endpoint;

export const connectorHostName = connectorsEndpoint;
export const trackingHostName = trackingEndpoint;
export const platformHostName = platformEndpoint;

export const SHOP_HOST_NAME = 'shopHostName';
